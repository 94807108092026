import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
declare const $: any;
@Component({
  selector: 'app-header-new',
  templateUrl: './header-new.component.html',
  styleUrls: ['./header-new.component.css']
})
export class HeaderNewComponent implements OnInit {
  @Input() categoryList: Array<{ id: number, name: string }> = [];
  @Output() categoryEvent = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
    this.SearchModel();
  }

  SearchModel() {
    $(document).ready(function () {
      $('#close-btn').click(function () {
        $('#search-overlay').fadeOut();
        $('#search-btn').show();
      });
      $('#search-btn').click(function () {
        $(this).hide();
        $('#search-overlay').fadeIn();
      });
    });
  }

  onCategoryChanged(categoryId: number) {
    this.categoryEvent.emit(categoryId);
    this.gotoSection();
  }

  gotoSection() {
    $(document).scrollTop(1100);
  }

}
