<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-9 pe-lg-2 ps-lg-1">
                    <div class="pt-3 pb-3">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb mb-0 p-0">
                                <li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
                                </li>
                                <li class="breadcrumb-item active" style="font-size: 15px;" aria-current="page">courses
                                </li>
                            </ol>
                        </nav>
                    </div>

                    <div class="row">
                        <div class="col-xl-12 mb-3 mt-2">
                            <div class="card border-0 " style="background-color: #f4f4f4;">
                                <div class="card-body">
                                    <div class="navs-card d-flex justify-content-between">
                                        <div class="flex-start">
                                            <ul class="nav nav-pills  mb-3" id="pills-tab" role="tablist">
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link active" id="pills-courses-tab"
                                                        data-bs-toggle="pill" data-bs-target="#pills-courses"
                                                        type="button" role="tab" aria-controls="pills-courses"
                                                        aria-selected="true">My Courses</button>
                                                </li>
                                               
                                               
                                                <li class="nav-item ">

                                                </li>
                                            </ul>
                                        </div>
                                        <div class="flex-end">
                                            <input type="text" class="form-control form-control-sm "
                                                placeholder="search category">
                                            <i class="fas fa-search"
                                                style="position: absolute;top: 25px;right: 30px;"></i>
                                        </div>
                                    </div>


                                    <div class="tab-content mt-3" id="pills-tabContent">
                                        <div class="tab-pane fade show active" id="pills-courses" role="tabpanel"
                                            aria-labelledby="pills-courses-tab">
                                            <div class="row">
                                                <div class="col-md-4 mb-4" *ngFor="let item of cources">
                                                    <a  routerLink='/home/viewCourse'>
                                                        <div class="card shadow-lg h-100">
                                                            <img src="" class="card-img-top" alt="">
                                                            <div class="card-body">
                                                                <h6 class="card-title">{{item.COURSE_NAME}}</h6>
                                                                <p class="card-text mb-2">Prof. Richard</p>
                                                                <div class="rating">
                                                                    <span><i class="fas fa-star checked"></i></span>
                                                                    <span><i class="fas fa-star checked"></i></span>
                                                                    <span><i class="fas fa-star checked"></i></span>
                                                                    <span><i class="fas fa-star"></i></span>
                                                                    <span><i class="fas fa-star me-3"></i></span>

                                                                    <span>(256)</span>
                                                                </div>
                                                                <div class="rating mt-2">
                                                                    <span><i class="fas fa-clock"></i></span>
                                                                    <span class="me-3">12 Hrs</span>
                                                                    <span><i class="fas fa-file-alt "></i></span>
                                                                    <span>23 Lessons</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>                                              





                                            </div>


                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>




                </div>
                <div class="col-xl-3 bg-white">
                    <div>

                        <div data-simplebar="true">

                            <div style="background-color:#FAFAFA;border-radius: 20px;" class="p-1 mb-3 mt-3 ps-2 pe-2">


                                <div class="app">
                                    <div class="app__main shadow-sm mt-3">
                                        <div class="calendar">
                                            <div id="calendar"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="upcoming mt-3">
                                    <h6>Upcoming </h6>
                                    <p>18-03-2022</p>

                                    <ul>
                                        <li>DBMS Assignment</li>
                                        <li>Cyber Law class</li>
                                    </ul>
                                </div>

                            </div>

                            <div class="card card-1 card-shadow right-cards mb-3">

                                <div class="card-body">
                                    <div class="text-center">
                                        <h6>What sessions would you like to prefer?</h6>
                                        <div class="btn-rounded-1 mt-4">
                                            <button type="button"
                                                class="btn btn-outline-primary px-4 py-1 me-2">Online</button>
                                            <button type="button"
                                                class="btn btn-outline-primary px-4 py-1">Offline</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="card card-1 card-shadow right-cards mb-3">

                                <div class="card-body">
                                    <div class="text-center">
                                        <h6>What sessions would you like to prefer?</h6>
                                        <div class="btn-rounded-1 mt-4">
                                            <button type="button"
                                                class="btn btn-outline-primary px-4 py-1 me-2">Online</button>
                                            <button type="button"
                                                class="btn btn-outline-primary px-4 py-1">Offline</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <a href="#" class="btn btn-sm small"
                                style="text-decoration: none; color:#0066FF; font-size: 12px;font-weight:500; ">View all
                                <i class="bx bx-right-arrow-alt"></i></a>


                        </div>
                    </div>
                </div>
            </div>





        </div>
    </div>
</div>