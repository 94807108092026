<!-- <div class="modal" id="myModal_skill" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg">
        <div class="modal-content"> -->

<!-- Modal Header -->
<!-- <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class="modal-title w-100"> Add Skill</h6>
                <h6 *ngIf="isEdit" class="modal-title w-100"> Edit Skill</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i class="fas fa-times"></i></button>
            </div> -->

<!-- Modal body -->
<div class="custom_container">
    <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
        <fieldset>
            <div class="row mx-4">
                <div class="col-xl-6 mb-3">
                    <label class=" form-label">Skill Name <span class="text-danger">*</span></label>
                    <div class=" inputGroupContainer">
                        <div class="input-group">
                            <input placeholder="Java,.Net,Angular,React js,Python" formControlName='SkillName'
                                type="text" class="form-control form-control-sm shadow-sm rounded-lg">

                        </div>
                        <div *ngIf="myForm.get('SkillName').touched">
                            <span class="text-danger" *ngIf="myForm.get('SkillName').hasError('required')">
                                This field is required.
                            </span>

                        </div>
                    </div>
                </div>
                <div class="col-xl-6 mb-3">
                    <label class=" form-label"> Software version <span class="text-danger">*</span></label>
                    <div class=" inputGroupContainer">
                        <div class="input-group">
                            <input type="text" formControlName='Version'
                                class="form-control form-control-sm shadow-sm rounded-lg">

                        </div>
                        <div *ngIf="myForm.get('Version').touched">
                            <span class="text-danger" *ngIf="myForm.get('Version').hasError('required')">
                                This field is required.
                            </span>

                        </div>
                    </div>
                </div>
                <div class="col-xl-6 mb-3">
                    <label class=" form-label">Experience(Yrs.) <span class="text-danger">*</span></label>
                    <div class=" inputGroupContainer">
                        <div class="input-group">
                            <input type="number" min="1" formControlName='Experience'
                                class="form-control form-control-sm shadow-sm rounded-lg">

                        </div>
                        <div *ngIf="myForm.get('Experience').touched">
                            <span class="text-danger" *ngIf="myForm.get('Experience').hasError('required')">
                                This field is required.
                            </span>

                        </div>
                    </div>
                </div>
                <div class="col-xl-6 mb-3">
                    <label class=" form-label line_2 rem_1">
                        Months <span class="text-danger">*</span></label>
                    <div class="     inputGroupContainer">
                        <div class="input-group">
                            <input type="number" min='1' placeholder="" class="form-control form-control-sm"
                                formControlName="Months">
                        </div>
                        <div *ngIf="myForm.get('Months').touched">
                            <span class="text-danger" *ngIf="myForm.get('Months').hasError('required')">
                                This field is required.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="form-group row">
                            <label class="col-md-4 control-label"  >Skill Name <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="Java,.Net,Angular,React js,Python"  formControlName='SkillName' type="text"
                                        class="form-control form-control-sm shadow-sm rounded-lg">

                                </div>
                                <div *ngIf="myForm.get('SkillName').touched">
                                    <span class="text-danger" *ngIf="myForm.get('SkillName').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div> -->
            <!-- <div class="form-group row pt-4">
                            <label class="col-md-4 control-label"> Software version <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="text" formControlName='Version'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                      
                                </div>
                                <div *ngIf="myForm.get('Version').touched">
                                    <span class="text-danger" *ngIf="myForm.get('Version').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div> -->
            <!-- <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">Last Used <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="number" min="1" formControlName='LastUsed'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                      
                                    
                                </div>
                                <div *ngIf="myForm.get('LastUsed').touched">
                                    <span class="text-danger" *ngIf="myForm.get('LastUsed').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div> -->
            <!-- <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">Experience(Yrs.) <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="number" min="1" formControlName='Experience'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                      
                                </div>
                                <div *ngIf="myForm.get('Experience').touched">
                                    <span class="text-danger" *ngIf="myForm.get('Experience').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div> -->



            <!-- <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Months <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="number" min='1' placeholder="" class="form-control"
                                        formControlName="Months">
                                </div>
                                <div *ngIf="myForm.get('Months').touched">
                                    <span class="text-danger" *ngIf="myForm.get('Months').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div> -->
        </fieldset>
    </form>

</div>
<!-- <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger btn-sm" id="md_close_skill" (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div> -->

<div class="text-center pt-4">
    <button type="button" class="btn btn-sm  btn-success" (click)="onSubmit(myForm)">{{btnTitle}}</button> &nbsp;
    <button type="button" class="btn btn-sm btn-danger" (click)="clearForm()">Clear</button> 
</div>
<!-- </div>
    </div>
</div> -->

<div>
    <!-- <div class="text-right">
        <button type="button" class="btn btn-primary" (click)="add()" data-bs-toggle="modal"
            data-bs-target="#myModal_skill">Add</button>

    </div> -->
    <div class="pt-5">
        <table *ngIf='table.length' class="table table-striped table-responsive-md btn-table table-bordered text-dark">
            <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                <tr>
                    <th>Skill Name</th>
                    <th>Software version</th>
                    <!-- <th>Last Used</th> -->
                    <th>Experience(Yrs.)</th>
                    <th>Months</th>
                    <th>Operation</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let item of table;let i=index' [hidden]='check(item)'>
                    <td>{{item.SKILLNAME}}</td>
                    <td>{{item.VERSION}}</td>
                    <!-- <td>{{item.LASTUSED}}</td> -->
                    <td>{{item.EXPERIANCE}}</td>
                    <td>{{item.MONTHS}}</td>
                    <td style="display: flex;">
                        <span class="edit_icon" style="cursor: pointer;" (click)="edit(item,i)">
                            <i class="fas fa-pen pt-1 mt-1"></i></span>
                    </td>

                </tr>
            </tbody>
        </table>
        <!-- <p class="text-center" *ngIf='!table.length'>No Records</p> -->
    </div>

</div>
