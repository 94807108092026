import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/pages/base.component';
import { CommonService } from 'src/app/services/common.service';
import { SamvaadUserRegister } from 'src/app/samvaad-user.component';
declare let $: any;
@Component({
  selector: 'app-tutor-signup',
  templateUrl: './tutor-signup.component.html',
  styleUrls: ['./tutor-signup.component.css']
})
export class TutorSignupComponent extends BaseComponent implements OnInit {

  signUpForm: FormGroup;
  submitted: boolean = false;
  RegUser = new SamvaadUserRegister();
  samvaadUserPWD: string = '';
  constructor(private fb: FormBuilder, CommonService: CommonService,
    private route: Router,
    toastr: ToastrService) {
    super(CommonService, toastr);
  }

  ngOnInit(): void {
    this.validationInit();
    this.signUpOnInit();
  }

  signUpOnInit() {
    this.signUpForm = this.fb.group({
      'name': ['', [Validators.required, Validators.maxLength(100)]],
      'mobile': ['', [Validators.required, Validators.pattern("^[0-9]*$"),
      Validators.minLength(10), Validators.maxLength(10)]],
      'email': ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]],
      'role': [true],
    })
  }

  get f() { return this.signUpForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.signUpForm.invalid) {
      this.toastr.warning('Please Enter All Mandatory Fields', 'SignUp');
      return;
    }
    let signUpData = this.signUpForm.getRawValue();
    //console.log('signup user :', this.signUpForm.getRawValue().trim());
    let [payLoad, URL] = [{}, ''];


    if (!signUpData.role) {
      URL = 'TenantRegistration/Create';
      payLoad = {
        TNT_NAME: signUpData.name,
        TNT_STATUS: true,
        TenantLogoUrl: "",
        SHOWLOGO: false,
        allow_proctoring: false,
        allow_exampad: false,
        TNT_CREATEDBY: "12345678",
        MobileNo: signUpData.mobile,
        emailid: signUpData.email,
        RoleId: 25//Tutor
      };
    }
    else {
      URL = 'Registration/SaveRegistration';
      payLoad = {
        title: 38,
        firstname: signUpData.name,
        lastname: " ",
        mobileno: signUpData.mobile,
        username: signUpData.email,
        password: "abc123",
        confirmPassword: "abc123",
        dob: "0001-01-01",
        Gender: 1,
        TENANT_CODE: 12345678,
        UserType: 26,//Student
        // Role: 3,//Member Or Trainee Or Student
        Role: 3,//Member Or Trainee Or Student
        Timezone: "India Standard Time",
        CREATEDBY: 12345678
      }
    }

    this.activeSpinner();
    this.CommonService.postCall(URL, payLoad).subscribe(
      (res: any) => {
        this.toastr.success('Created Successfully');
        if (payLoad['RoleId'] == 25) {
          this.checkSamvaadUserExists(signUpData.email, payLoad, res);
          // this.registerAsSamvaadUser(payLoad, res);
        }
        this.clear();
        this.deactivateSpinner();
        this.route.navigate(['/login']);
      }, err => {
        this.toastr.error(err.error ? err.error.text || err.error : err); this.deactivateSpinner()
      })

  }

  checkSamvaadUserExists(loginEmail: string, payLoad: any, Data: any, isNewUser: boolean = false) {
    this.activeSpinner();
    this.CommonService.getCall(`nojwt/login/getUserDeatilsBy/${loginEmail}`, '', true).subscribe(
      (res: any) => {
        if (isNewUser) {
          if (res.data.length > 0) {
            const { password } = res.data[0];
            this.samvaadUserPWD = password;
            this.SaveSamvaadUser(loginEmail, this.samvaadUserPWD, Data.TNT_CODE);
          }
        }
        else {
          if (res.data.length > 0) {
            const { password } = res.data[0];
            this.samvaadUserPWD = password;
            this.registerAsSamvaadUser(payLoad, Data, true, password);
          }
          else
            this.registerAsSamvaadUser(payLoad, Data);
        }

        this.clear();
        this.deactivateSpinner();
        this.route.navigate(['/login']);
      }, err => {
        this.toastr.error(err.error ? err.error.text || err.error : err); this.deactivateSpinner()
      })
  }

  registerAsSamvaadUser(item: any, data: any, isExistingSamvaadUser = false, pwd: string = '') {

    if (isExistingSamvaadUser == false) {
      //Register as samvaad user
      let payLoad = {
        ...this.RegUser,
        name: item['TNT_NAME'],
        mobileNO: item['MobileNo'],
        email: item['emailid'],
        password: data.PWD
      }
      this.CommonService.postCall("nojwt/login/saveOrUpdate", payLoad, true).subscribe((res: any) => {
        console.log(res);
        this.checkSamvaadUserExists(item.emailid, null, data, true);
        // this.SaveSamvaadUser(item.emailid, this.samvaadUserPWD, data.TNT_CODE);
      }, e => { });
    }
    else
      this.SaveSamvaadUser(item.emailid, this.samvaadUserPWD, data.TNT_CODE);

    /*
        let insertPayload = {
          USERNME: item['emailid'],
          // PASSWORD: isExistingSamvaadUser ?pwd: item.PWD,
          PASSWORD: this.samvaadUserPWD,
          TNT_CODE: data.TNT_CODE
        }
        //Insert as samvaad user
        this.CommonService.postCall("Registration/InsertSamvaadUser", insertPayload).subscribe((res: any) => {
          console.log(res);
        }, e => { });
        */
  }

  SaveSamvaadUser(USERNME: string, PASSWORD: string, TNT_CODE: string) {

    let insertPayload = {
      USERNME, PASSWORD, TNT_CODE
      //USERNME: item['emailid'],
      // PASSWORD: isExistingSamvaadUser ?pwd: item.PWD,
      //PASSWORD: this.samvaadUserPWD,
      //TNT_CODE: data.TNT_CODE
    }
    //Insert as samvaad user
    this.CommonService.postCall("Registration/InsertSamvaadUser", insertPayload).subscribe((res: any) => {
      console.log(res);
    }, e => { });
  }
  cancel() {

  }

  clear() {
    this.signUpOnInit();
    this.submitted = false;
  }

  validationInit() {
    let self = this;
    $('.input100').each(function () {
      $(this).on('blur', function () {
        if ($(this).val().trim() != "") {
          $(this).addClass('has-val');
        }
        else {
          $(this).removeClass('has-val');
        }
      })
    })

    var input = $('.validate-input .input100');

    $('.validate-form').on('submit', function () {
      var check = true;

      for (var i = 0; i < input.length; i++) {
        if (validate(input[i]) == false) {
          showValidate(input[i]);
          check = false;
        }
      }
      // if (check) {
      //   self.registerUser()
      // }
      return check;
    });


    $('.validate-form .input100').each(function () {
      $(this).focus(function () {
        hideValidate(this);
      });
    });

    function validate(input) {
      // return true
      if ($(input).attr('type') == 'email' || $(input).attr('name') == 'email') {
        return true
        if ($(input).val().trim().match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null) {
          return false;
        }
      }
      else {
        if ($(input).val().trim() == '') {
          return false;
        }
      }
    }

    function showValidate(input) {
      var thisAlert = $(input).parent();
      $(thisAlert).addClass('alert-validate');
    }

    function hideValidate(input) {
      var thisAlert = $(input).parent();
      $(thisAlert).removeClass('alert-validate');
    }

    var showPass = 0;
    $('.btn-show-pass').on('click', function () {
      if (showPass == 0) {
        $(this).next('input').attr('type', 'text');
        $(this).find('i').removeClass('zmdi-eye');
        $(this).find('i').addClass('zmdi-eye-off');
        showPass = 1;
      }
      else {
        $(this).next('input').attr('type', 'password');
        $(this).find('i').addClass('zmdi-eye');
        $(this).find('i').removeClass('zmdi-eye-off');
        showPass = 0;
      }

    });

  }

  numberValidate(event: any) {
    event.target.value = event.target.value.replace(/[^0-9]*/g, '');
  }
  nameValidate(event: any) {
    event.target.value = event.target.value.replace(/[^A-Za-z. ]*/g, '');
  }

}
