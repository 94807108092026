import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-all-details',
  templateUrl: './view-all-details.component.html',
  styleUrls: ['./view-all-details.component.css']
})
export class ViewAllDetailsComponent implements OnInit {
  @Input() achivementsList: Array<{ count: number, usertype: string }> = [];
  constructor() { }

  ngOnInit(): void { }

  getCount(usertype: string): number {
    const count: number = this.achivementsList.find(e => e.usertype == usertype)?.count || 0;
    return count;
  }

}
