<!-- navbar header start -->
<header>
    <nav class="navbar navbar-expand-lg navbar-light fixed-top bg-white shadow-lg">
        <div class="container">
            <a class="navbar-brand text-white " href="#"> <img class="samvaad-logo"
                    src="assets/img/samvaad_tutor_logo.png" alt="samvaad Tutor"></a>
            <a class="navbar-toggler border-3 px-2" type="button" data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                <i class="fa-solid fa-bars"></i>
            </a>
            <div class="offcanvas offcanvas-start-lg bg-nav" tabindex="-1" id="offcanvasExample"
                aria-labelledby="offcanvasExampleLabel">
                <div class="offcanvas-header  d-lg-none">
                    <h5 class="offcanvas-title text-white" id="offcanvasExampleLabel"><img class="samvaad-logo"
                            src="assets/img/samvaad_tutor_logo.png" alt="samvaad Tutor"></h5>
                    <a href="javascript:void(0) " class="text-reset p-0" data-bs-dismiss="offcanvas" aria-label="close">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFFFFF"
                            class="bi bi-x-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path
                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </a>

                </div>
                <div class="offcanvas-body p-lg-0 ">
                    <ul class="navbar-nav">
                        <!-- <li class="nav-item">
                                <a class="nav-link active" aria-current="page" href="#">Home</a>
                            </li> -->
                        <li class="nav-item d-block d-xl-none d-lg-none">
                            <div class="text-center text-dark"><img src="assets/img/avtar.png"
                                    class="img-fluid rounded-circle border" alt="" height="50px" width="50px"> </div>
                            <a class="nav-link btn-login text-center py-2" style="padding-right: 0 !important;"
                                aria-current="page" [routerLink]="['/login']"><button
                                    class="btn btn-sm btn_white">Login</button></a>
                        </li>
                        <li class="nav-item dropdown dropdown1 catalog">
                            <a class="nav-link dropdown-toggle course-1 btn-border py-2 px-xl-4 rounded mt-2" href="#"
                                id="CoursesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Categories
                            </a>

                            <ul class="dropdown-menu dropdown-menu1 catalog-menu pt-0 pb-0  animate slideIn rounded-0 mt-0 shadow-lg"
                                aria-labelledby="CoursesDropdown">

                                <div class="container">
                                    <div class="row p-xl-4">
                                        <div *ngFor="let category of categoryList;let ind=index"
                                            class="col-xl-4 megamenu-1 ps-xl-4 pe-xl-4"
                                            [ngClass]="((ind+1)%3===0)?'border-0':'megamenu-sm'">
                                            <ul class="course-list ps-0">
                                                <li>
                                                    <a class="nav-link" (click)="onCategoryChanged(category.id)">
                                                        <i class="fas fa-chevron-right fa-sm"></i>
                                                        &nbsp;{{category.name}}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="float-xl-end float-end mb-3 me-3">
                                                <!-- <a href="" class="">
                                                    <span class="text-danger">Learn More
                                                        <i class="fas fa-angle-double-right"></i>
                                                    </span>
                                                </a> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ul>
                        </li>
                    </ul>
                    <ul class="navbar-nav ms-xl-auto ms-md-auto">
                        <li class="nav-item d-xl-block d-lg-block d-none py-2">
                            <!-- partial:index.partial.html -->
                            <i id="search-btn" class="fa fa-search fa-sm mt-1"></i>
                            <div id="search-overlay" class="search_block">
                                <div class="centered">
                                    <div id='search-box'>
                                        <i id="close-btn" class="fa fa-times fa-2x"></i>
                                        <form action='/search' id='search-form' method='get' target='_top'>
                                            <input id='search-text' name='q' placeholder='Search' type='text' />
                                            <button id='search-button' type='submit'>
                                                <span>Search</span>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <!-- partial -->
                        </li>

                        <li class="nav-item">
                            <a class="nav-link btn-login" aria-current="page" [routerLink]="['/login']">Login</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link btn btn-signup  py-2 mt-2" style="font-weight: 600;" type="button"
                                [routerLink]="['/signup']" aria-current="page">Signup</a>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </nav>
</header>
<!-- navbar header End -->