<!-- <div>
  <div style="margin-top:70px;">
    <div class="content-wrapper p-0"> -->

<!-- banner -->
<!-- <div class="container-fluid "> -->


<!-- <div class="row">
          <div class="col-xl-12">
            <img src="./assets/img/strip.png" class="img-fluid w-100" />
          </div>
        </div> -->
<!-- content -->


<!-- <div class="row">
          <div class="col-xl-8 pt-4">
            
            <div class="container-fluid" *ngIf="roleId==3">
              <div id="accordion" class="accordion">
                  <div class="card mb-0 active">
                      <div class="card-header collapsed accordion1" data-toggle="collapse" href="#collapseOne">
                          <a class="card-title">
                            My Enrolled Courses
                          </a>
                      </div>
                      <div id="collapseOne" class="card-body collapse show" data-parent="#accordion" >
                        <div *ngIf="course.classes&&course.classes.length">
                          <h5>Courses that you have enrolled for</h5>
                          <div class="table-responsive">
                          <table class="table table-striped table-responsive-md btn-table table-bordered text-dark">
                            <thead>
                              <tr>
                                <th> Name</th> -->
<!-- <th>Category</th> -->
<!-- <th>Course
                                  Content</th>
                                <th>Exam</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of course.classes">
                                <td>{{item.Name}}</td> -->
<!-- <td>Medical</td> -->
<!-- <td><a class="a_click" (click)="navigate(item,'home/learningmaterial')">view</a></td>
                                <td><a class="a_click" (click)="navigate(item,'home/postassessment')">Take Exam</a></td>
                              </tr>
                            </tbody>
                          </table>
                          </div>
                        </div>
                        <div class="text-dark" *ngIf="!course.classes|| !course.classes.length">
                          No records to Display
                        </div>
                      </div>
                  
                      <div class="card-header collapsed accordion1 mt-1" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                          <a class="card-title">
                            Online Sessions
                          </a>
                      </div>
                      <div id="collapseTwo" class="card-body collapse" data-parent="#accordion" >
                        <div *ngIf="course.sessions&&course.sessions.length">
                          <h5>Online Sessions for today</h5>
                          <table class="table table-striped table-responsive-md btn-table text-dark">
                            <thead>
                              <tr>
                                <th> Session Name </th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Join</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of course.sessions">
                                <td>{{item.SessionName}}</td>
                                <td>{{item.StartTime}}</td>
                                <td>{{item.EndTime}}</td>
                                <td><a *ngIf='item.URL' (click)='joinSession(item)' >Join Session</a>
                                  <span *ngIf='!item.URL' >Host Not Joined</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div  class="text-dark" *ngIf="!course.sessions || !course.sessions.length">
                          No records Display
                        </div>
                      </div>
                  
                   
                  </div>
              </div>
          </div> -->

<!-- <div class="container-fluid" *ngIf="roleId!=2">

              <div class="accordion" id="accordionExample" >
                <div class="card active mb-1">
                  <div class="card-header p-1 cardheader-bg collapsed" data-toggle="collapse" id="headingOne">
                    <h2 class="mb-0">
                      <button class="btn btn-link p-1 btn-block text-white text-left" type="button"
                        data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne">
                        My Enrolled Courses
                      </button>
                    </h2>
                  </div>

                  <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                    data-parent="#accordionExample">
                    <div class="card-body p-3">

                      <div *ngIf="course.classes&&course.classes.length">
                        <h5>Courses that you have enrolled for</h5>
                        <table class="table table-striped table-responsive-md btn-table table-bordered">
                          <thead>
                            <tr>
                              <th> Name</th>
                            
                              <th>Course
                                Content</th>
                              <th>Exam</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of course.classes">
                              <td>{{item.Name}}</td>
                             <td>Medical</td>
                              <td><a class="a_click" (click)="navigate(item,'home/learningmaterial')">view</a></td>
                              <td><a class="a_click" (click)="navigate(item,'home/postassessment')">Take Exam</a></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div *ngIf="!course.classes|| !course.classes.length">
                        No records to Display
                      </div>
                    
                    </div>
                  </div>
                </div>
                <div class="card active  mb-1">
                  <div class="card-header p-1 cardheader-bg collapsed" data-toggle="collapse" id="headingTwo">
                    <h2 class="mb-0">
                      <button class="btn btn-link p-1 btn-block text-white text-left" type="button"
                        data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                        aria-controls="collapseTwo">
                        Online Sessions
                      </button>
                    </h2>
                  </div>
                  <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div class="card-body">
                  
                    </div>
                  </div>
                </div>
                
              </div>
            </div> -->


<!-- </div> -->
<!-- <div class="col-xl-4  pt-4 pr-3">


            <div class="container ">
              <ul class="nav nav-tabs tabactive ml-3" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active tabactive" data-toggle="tab" href="#tabs-1" role="tab">Discussion</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab">News</a>
                </li> -->

<!-- </ul> -->
<!-- Tab panes -->
<!-- <div class="tab-content bg-white shadow" style="border-radius:15px;">
                <div class="tab-pane    active p-2" id="tabs-1" role="tabpanel">
                  <div class="list list-hover" >
                    <ul [innerHTML]='data.ulDiscussions'></ul>
                  </div> -->
<!-- <div class="list list-hover">
          
            <div class="list-item hoverable  p-lg-2 mb-0">
              <div class="d-flex align-items-center">
   
                <div class="symbol symbol-40 symbol-light mr-4">
                  <span class="symbol-label bg-hover-white">
                    <img src="https://via.placeholder.com/30/09f/fff.png%20C/O%20https://placeholder.com/" class="h-50 align-self-center">
                  </span>
                </div>
           
                <div class="d-flex flex-column flex-grow-1 mr-2">
                  <span class="text-dark-75 font-size-h6 mb-0">Bravio Application</span>
                  <a href="#" class="text-muted text-hover-primary font-weight-bold">By James</a>
                </div>
               
              </div>
            </div>
            
            <div class="list-item hoverable  p-lg-2 mb-0">
              <div class="d-flex align-items-center">
                
                <div class="symbol symbol-40 symbol-light mr-4">
                  <span class="symbol-label bg-hover-white">
                    <img src="https://via.placeholder.com/30/09f/fff.png%20C/O%20https://placeholder.com/" class="h-50 align-self-center">
                  </span>
                </div>
              
                <div class="d-flex flex-column flex-grow-1 mr-2">
                  <span class="text-dark-75 font-size-h6 mb-0">Quick Reports</span>
                  <a href="#" class="text-muted text-hover-primary font-weight-bold">By Ana</a>
                </div>
             
              </div>
            </div>
          
            <div class="list-item hoverable  p-lg-2 mb-0">
              <div class="d-flex align-items-center">
               
                <div class="symbol symbol-40 symbol-light mr-4">
                  <span class="symbol-label bg-hover-white">
                    <img src="https://via.placeholder.com/30/09f/fff.png%20C/O%20https://placeholder.com/" class="h-50 align-self-center">
                  </span>
                </div>
                
                <div class="d-flex flex-column flex-grow-1 mr-2">
                  <span class="text-dark-75 font-size-h6 mb-0">CRM Reporting Tool</span>
                  <a href="#" class="text-muted text-hover-primary font-weight-bold">By Adam</a>
                </div>
        
        
             
        
                <div> 
        
                
                </div>
           
              </div>
            </div>
       
            <div class="list-item hoverable  p-lg-2 mb-0">
              <div class="d-flex align-items-center">
           
                <div class="symbol symbol-40 symbol-light mr-4">
                  <span class="symbol-label bg-hover-white">
                    <img src="https://via.placeholder.com/30/09f/fff.png%20C/O%20https://placeholder.com/" class="h-50 align-self-center">
                  </span>
                </div>
                
                <div class="d-flex flex-column flex-grow-1 mr-2">
                  <span class="text-dark-75 font-size-h6 mb-0">CRM Reporting Tool</span>
                  <a href="#" class="text-muted text-hover-primary font-weight-bold">By Adam</a>
                </div>
              
              </div>
            </div>
            
                      <div class="list-item hoverable  p-lg-2 mb-0">
                        <div class="d-flex align-items-center">
                         
                          <div class="symbol symbol-40 symbol-light mr-4">
                            <span class="symbol-label bg-hover-white">
                              <img src="https://via.placeholder.com/30/09f/fff.png%20C/O%20https://placeholder.com/" class="h-50 align-self-center">
                            </span>
                          </div>
                   
                          <div class="d-flex flex-column flex-grow-1 mr-2">
                            <span class="text-dark-75 font-size-h6 mb-0">CRM Reporting Tool</span>
                            <a href="#" class="text-muted text-hover-primary font-weight-bold">By Adam</a>
                          </div>
                         
                        </div>
                      </div>
                         
            <div class="list-item hoverable  p-lg-2 mb-0">
              <div class="d-flex align-items-center">
          
                <div class="symbol symbol-40 symbol-light mr-4">
                  <span class="symbol-label bg-hover-white">
                    <img src="https://via.placeholder.com/30/09f/fff.png%20C/O%20https://placeholder.com/" class="h-50 align-self-center">
                  </span>
                </div>
               
                <div class="d-flex flex-column flex-grow-1 mr-2">
                  <span class="text-dark-75 font-size-h6 mb-0">CRM Reporting Tool</span>
                  <a href="#" class="text-muted text-hover-primary font-weight-bold">By Adam</a>
                </div>
        
              </div>
              
            
            </div>
        
        
            <div class="list-item hoverable pb-5 mb-0">
              <div class="float-right">
         <button class="btn btn-sm btn-primary">View More...</button>
              
              </div>
              
            
            </div>
           
          </div> -->

<!-- </div>
                <div class="tab-pane fade in p-2" id="tabs-2" role="tabpanel"> -->
<!-- <p>News</p> -->
<!-- <ul [innerHTML]='data.ulNews'></ul>
                </div>

              </div>


            </div>

          </div>


        </div>

      </div>



    </div> -->
<!-- content-wrapper ends -->
<!-- partial:partials/_footer.html -->

<!-- partial -->
<!-- </div>
</div> -->



































<div class="wrapper">
  <div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
      <div class="container-fluid" *ngIf="roleId==3 || roleId==2">
        <div class="row">
          <div class="col-xl-9 pe-lg-2 ps-lg-1">


            <div class="pt-3 pb-3">

              <div class="mt-3">
                <h2 style="font-size:22px; color: #2B3674;">Hi {{userName}}</h2>
                <h2 style="font-size: 14px; color: #707EAE;">Welcome to Samvaad</h2>
              </div>
            </div>


            <div class="row">

              <div class="col-xl-6 mb-4">
                <div class="card card-1  card-shadow">
                  <div class="card-header border-0 pt-3 pb-3">
                    <h5>News</h5>
                  </div>
                  <div class="card-body smooth-scroll  px-0 pt-0 style-1">

                    <div class="bg-light pt-2 pb-2 ps-3 pe-3" *ngFor="let item of newsList">
                      <p class="title1 mb-1" data-bs-toggle="modal" data-bs-target="#newsModal"
                        (click)="getNews(item.EVENT_ID)"><a href="javascipt:;" style="color: #5A5A5A">{{item.EVENT_NAME}}</a></p>
                      <p class="dt-time mb-0">{{item.EVENT_DESCRIPTION}}</p>

                    </div>


                  </div>
                </div>
              </div>



              <div class="col-xl-6 mb-4">
                <div class="card card-1 card-shadow">
                  <div class="card-header border-0 pt-3 pb-3">
                    <h5>Ongoing Sessions</h5>
                  </div>
                  <div class="card-body smooth-scroll  px-0 pt-0 style-1">

                    <div class="bg-light pt-2 pb-2 ps-3 pe-3" *ngIf="course.sessions&&course.sessions.length">
                      <h5>Online Sessions for today</h5>
                      <!-- <p class="title1 mb-1">Class name with the extra length text will be </p> -->
                      <div class="clearfix"  *ngFor="let item of course.sessions">
                        <div class="float-start">
                          <p class="dt-time mb-0"> {{item.SessionName}}</p>
                          <p class="dt-time mb-0"> {{item.StartTime}} </p>
                          <!-- <p class="dt-time mb-0"> {{item.EndTime}} </p> -->
                        </div>

                        <div class="float-end">
                          <button class="btn btn-sm btn-primary" (click)='joinSession(item)' *ngIf='item.URL'>Join Class</button>
                          <span *ngIf='!item.URL' >Host Not Joined</span>
                          <span *ngIf="roleId==2">
                            <button class="btn btn-sm btn-primary"  (click)='joinSession(item)' *ngIf='item.URL'>Start Class</button>
                          </span>
                          
                        </div>

                      </div>
                      <div  class="text-dark" *ngIf="!course.sessions || !course.sessions.length">
                        No records Display
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12 mb-4" *ngIf="roleId==3">
                <div class="card card-1 card-shadow">
                  <div class="card-header border-0 pt-3 pb-3">
                    <h5>Assesment</h5>
                  </div>
                  <div class="card-body table-style px-0 pt-0">

                    <div class="bg-light pt-2 pb-2 ps-3 pe-3">

                      <div class="clearfix" *ngFor="let item of course.classes">
                        <div class="float-start">
                          <p class="title1 mb-1">{{item.Name}} </p>
                          <!-- <p class="dt-time mb-0"> by Prof. Lakshmi Kumari kamaneni </p> -->

                        </div>

                        <div class="float-end">
                          <button class="btn btn-sm btn-outline-primary mt-3"
                            (click)="navigate(item,'home/learningmaterial')">View Files</button> &nbsp; &nbsp;
                          <button class="btn btn-sm btn-primary mt-3"
                            (click)="navigate(item,'home/postassessment')">Start Test</button>
                        </div>

                      </div>
                    </div>

                    <!-- <div class="bg-white pt-2 pb-2 ps-3 pe-3">

                      <div class="clearfix">
                        <div class="float-start">
                          <p class="title1 mb-1">Class name with the extra length text will be </p>
                          <p class="dt-time mb-0"> by Prof. Lakshmi Kumari kamaneni </p>

                        </div>

                        <div class="float-end">
                          <button class="btn btn-sm btn-outline-primary mt-3">View Files</button> &nbsp; &nbsp;
                          <button class="btn btn-sm btn-primary mt-3">Start Test</button>
                        </div>

                      </div>
                    </div> -->









                    <div class="text-center">
                      <!-- <a href="#" style="text-decoration: none; color:#9C9C9D; font-size: 13px;">View all <i
                          class="bx bx-right-arrow-alt"></i></a> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-12 mb-4">


                <div class="card card-1 card-shadow">
                  <div class="card-body table-style">
                    <nav class="tabs-style ">
                      <div class="float-start pt-2">
                        <h5>My Courses</h5>
                      </div>
                      <div class="nav nav-tabs border-0 float-end" id="nav-tab" role="tablist">
                        <button class="nav-link active border-0" id="nav-all-tab" data-bs-toggle="tab"
                          data-bs-target="#nav-all" type="button" role="tab" aria-controls="nav-all"
                          aria-selected="true">All</button>
                        <button class="nav-link border-0" id="nav-ongoing-tab" data-bs-toggle="tab"
                          data-bs-target="#nav-ongoing" type="button" role="tab" aria-controls="nav-ongoing"
                          aria-selected="false">Ongoing</button>
                        <button class="nav-link border-0" id="nav-meetings-tab" data-bs-toggle="tab"
                          data-bs-target="#nav-meetings" type="button" role="tab" aria-controls="nav-meetings"
                          aria-selected="false">Compeleted</button>

                      </div>
                    </nav>
                    <div class="tab-content mt-5" id="nav-tabContent">
                      <div class="tab-pane fade show active" id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab"
                        style="display: inline-block;">

                        <div class="table-responsive">


                          <table class="table table-borderless" style="width: 100%;">
                            <tbody>
                              <tr *ngFor="let item of cources">
                                <td class="pt-4">
                                  <span class="mt-2"
                                    style="background-color: #F6F8FD; border-radius: 50%; padding: 1rem;">
                                    <img src="" class="" />

                                  </span>

                                </td>
                                <td>
                                  <p class="" style="margin: 0px !important ;">{{item.COURSE_NAME}}</p>
                                  <span class="sub-title pt-0">Class</span>
                                </td>
                                <td class="pt-3" style="width:25%;">
                                  <div class="progress progress1">
                                    <div class="progress-bar" role="progressbar" style="width: 90%;" aria-valuenow="90"
                                      aria-valuemin="0" aria-valuemax="100"></div>
                                  </div>
                                </td>
                                <td class=" pt-3"><i class="fas fa-star star-icon"></i> <span>4.3</span></td>
                                <td class="btn-rounded-1 pt-2" style="width: 20%;"><button type="button"
                                    class="btn btn-outline-primary" routerLink='/home/course'>Resume Course</button>
                                </td>
                              </tr>

                            </tbody>
                          </table>

                        </div>

                        <div class="float-end">
                          <!-- <a href="#" class="btn btn-sm"
                            style="text-decoration: none; color:#0066FF; font-size: 16px;font-weight:500; ">View all
                            <i class="bx bx-right-arrow-alt"></i></a> -->
                        </div>

                      </div>
                      <div class="tab-pane fade" id="nav-ongoing" role="tabpanel" aria-labelledby="nav-ongoing-tab">


                        45
                      </div>
                      <div class="tab-pane fade" id="nav-meetings" role="tabpanel" aria-labelledby="nav-meetings-tab">


                        89

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="col-xl-3 bg-white">
            <div>

              <div data-simplebar="true">

                <div style="background-color:#FAFAFA;border-radius: 20px;" class="p-1 mb-3 mt-3 ps-2 pe-2">


                  <div class="app" >
                    <div class="app__main shadow-sm mt-3">
                      <div class="calendar">
                        <div id="calendar"></div>
                      </div>
                    </div>
                  </div>
                  <div class="upcoming mt-3" *ngIf="false">
                    <h6>Upcoming </h6>
                    <p>18-03-2022</p>

                    <ul>
                      <li>DBMS Assignment</li>
                      <li>Cyber Law class</li>
                    </ul>
                  </div>

                </div>

                <div class="card card-1 card-shadow right-cards mb-2">

                  <div class="card-body">
                    <div class="text-center">
                      <!-- <h6>What sessions would you like to prefer?</h6> -->
                      <!-- <div class="btn-rounded-1 mt-4"> -->
                        <!-- <button type="button" class="btn btn-outline-primary px-4 py-1 me-2">Online</button> -->
                        <button type="button" class="btn btn-outline-primary px-4 py-1 " (click)="polls()">&nbsp; Take Polls &nbsp;</button>
                      <!-- </div> -->
                    </div>
                  </div>
                </div>


                <div class="card card-1 card-shadow right-cards mb-3">

                  <div class="card-body">
                    <div class="text-center">
                      <!-- <h6>What sessions would you like to prefer?</h6>
                      <div class="btn-rounded-1 mt-4"> -->
                        <button type="button" class="btn btn-outline-primary px-4 py-1 " (click)="surveys()">Take Surveys</button>
                        <!-- <button type="button" class="btn btn-outline-primary px-4 py-1">Offline</button>
                      </div> -->
                    </div>
                  </div>
                </div>


                <!-- <a href="#" class="btn btn-sm small"
                  style="text-decoration: none; color:#0066FF; font-size: 12px;font-weight:500; ">View all <i
                    class="bx bx-right-arrow-alt"></i></a> -->


              </div>
            </div>
          </div>
        </div>





      </div>
    </div>
  </div>
</div>




<div class="modal fade" id="newsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">news</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal">&times;</button>
        <!-- <h4 class="modal-title" >Upcoming Event</h4> -->
        <!-- <button type="button" class="close" data-dismiss="modal">&times;</button> -->
      </div>&nbsp;
      <div *ngIf='isNews'>
        <div class="container">
          <div class="row col-12">
            <div class="col-3">
              <img src="{{news.EVENT_IMAGE}}" alt="News">
            </div>
            <div class="col-9">
              <h3>{{news.EVENT_NAME}}</h3>
              <!-- <h5 class="description">{{news.EVENT_DESCRIPTION}}</h5> -->
              <P class="description">{{news.EVENT_DESCRIPTION}}</P>

            </div>
          </div>

        </div>


      </div>
      <div class="modal-footer">


        &nbsp;
        <button class="btn btn-danger" id='close' (click)="close()" data-bs-dismiss="modal" (click)="close()">
          Close</button>
      </div>
    </div>

  </div>
</div>