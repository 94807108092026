<!-- <div *ngIf='false' class="loginbg" style="background-image: url('./assets/img/samvaad_tutor_logo.png');">
    <div class="container">
        <div class="row">
            <div class="offset-xl-4 col-xl-4">
                <div class="card  loginform">

                    <div class="card-body p-1 loginform">
                        <div id="login-row" class="row justify-content-center align-items-center">
                            <div id="login-column" class="col-xl-12">
                                <div id="login-box" class="col-xl-12">
                                    <form id="login-form" class="form" #form="ngForm" (ngSubmit)="registerUser(form)">
                                        <h3 class="text-center text-info">Login</h3>
                                        <div class="form-group">
                                            <label for="username" class="text-info">Username:</label><br>
                                        
                                            <input type="email" class="form-control" id="userName" placeholder="Email"
                                                email="true" name="userName" userName required ngModel
                                                #userName="ngModel">
                                            <span class="help-bpx" *ngIf="userName.touched && !userName.valid ">Please
                                                enter the Email </span>
                                        </div>
                                        <div class="form-group">
                                            <label for="password" class="text-info">Password:</label><br>
                                         
                                            <input type="password" class="form-control" id="password"
                                                placeholder="password" name="password" password required ngModel
                                                #password="ngModel">
                                            <span class="help-bpx" *ngIf="password.touched && !password.valid ">Please
                                                enter the password </span>
                                        </div>
                                        <div class="text-center">
                                            <div class="form-group">
                                               
                                                <button type="submit" class="btn btn-info btn-sm btn-block"
                                                    id="register" [disabled]="!form.valid">Submit</button>
                                            </div>
                                        </div>

                                       
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>




</div> -->

<div class="limiter">
    <div class="container-login100" style="background-image: url('../../../assets/images/dhanush/bg-image.png');">
        <div class="wrap-login100">
            <div class="text-center"> <img src="../../../assets/img/samvaad_tutor_logo.png" [routerLink]="['']" style="cursor:pointer;" class="img-fluid" /></div>
            <br>
            <form class="login100-form validate-form" [formGroup]="loginForm" autocompleteOff>
                <span class="login100-form-title p-b-24"> <span style="font-size:22px;">Welcome to Shiksion</span> <br>
                    <!-- <span style="font-size:15px;">Learning Management System</span>  -->
                </span>
                <div class="wrap-input100 validate-input mt-4" data-validate="Valid email is: a@b.c">
                    <input class="input100" id="userName" type="text" autocompleteOff   name="userName" formControlName="userName"
                        [ngClass]="{ 'is-invalid': submitted && f.userName.errors }">
                    <span class="focus-input100" data-placeholder="Email"></span>
                    <div *ngIf="submitted && f.userName.errors" class="invalid-feedback">
                        <div *ngIf="f.userName.errors.required">EmilId is
                            required</div>
                    </div>
                </div>
                <div class="wrap-input100 validate-input" data-validate="Enter password"> <span class="btn-show-pass">
                        <!-- <i class="zmdi zmdi-eye"></i> --><i class="fa" id="viewPassword"
                        [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}"
                        (click)="toggleFieldTextType()"></i></span>
                    <input class="input100" id="password" type="password" autocompleteOff name="pass" formControlName="password"
                    [type]="fieldTextType ?'text':'password'"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                    <span class="focus-input100" data-placeholder="Password"></span>
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is
                            required</div>
                    </div>
                </div>
                <div class="container-login100-form-btn">
                    <div class="wrap-login100-form-btn">
                        <div class="login100-form-bgbtn"></div>
                        <button type="submit" class="login100-form-btn" value="login" (click)="registerUser(loginForm)">
                            Login </button>
                    </div>
                </div>
                <!-- <div class="text-center p-t-23"> <span class="txt1"> Don’t have an account? </span> <a class="txt2" href="#"> Sign Up </a> </div> -->
            </form>
            <div class="container-login100-form-btn">
                <span>Forgot password? <a style="cursor:pointer" (click)="onForgotPasswordClick()"><u>ForgotPassword</u></a></span>
                <span>Don't have an account? <a [routerLink]="['/signup']"><u>SignUp</u></a></span>
            </div>
        </div>
    </div>
</div>