<div class="limiter">
    <div class="container-login100" style="background-image: url('../../../assets/images/dhanush/bg-image.png');">
        <div class="wrap-login100">
            <div class="text-center"> <img src="../../../../assets/img/samvaad_tutor_logo.png" [routerLink]="['']"
                    style="cursor:pointer;" class="img-fluid" />
            </div>
            <br>
            <form class="login100-form validate-form" [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
                <span class="login100-form-title p-b-24"> <span style="font-size:22px;">Sign Up</span> </span> <br>
                <!-- <span style="font-size:15px;">Learning Management System</span> -->
                <div class="wrap-input100 validate-input mt-4" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                    <input class="input100" formControlName="name" type="text" name="name" maxlength="100"
                        (input)="nameValidate($event)">
                    <span class="focus-input100" data-placeholder="Full Name"></span>
                </div>
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">Full name is required</div>
                </div>
                <div class="wrap-input100 validate-input mt-4"
                    [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }">
                    <input class="input100" formControlName="mobile" type="text" name="mobile" maxlength="10"
                        (input)="numberValidate($event)">
                    <span class="focus-input100" data-placeholder="Mobile"></span>
                </div>
                <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                    <div *ngIf="f.mobile.errors.required">Mobile is required</div>
                    <div *ngIf="f.mobile.errors.pattern">Please enter valid mobile</div>
                    <div *ngIf="f.mobile.errors.minlength">Please enter 10 digit number</div>
                </div>
                <div class="wrap-input100 validate-input mt-4" data-validate="Valid email is: a@b.c"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                    <input class="input100" formControlName="email" type="text" name="email" maxlength="100">
                    <span class="focus-input100" data-placeholder="Email"></span>
                </div>
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.pattern">Please enter valid email</div>
                </div>
                <div class="mt-4">
                    <div>
                        <label class="input-group-append me-3">
                            <strong>Choose Role :</strong>
                        </label>
                        <input id="student" type="radio" name="role" formControlName="role" [value]="true" /><label
                            for="student" class="ms-1">
                            Student</label>
                        &nbsp;&nbsp;
                        <input id="trainer" type="radio" name="role" formControlName="role" [value]="false" /><label
                            for="trainer" class="ms-1">
                            Trainer</label>
                    </div>
                </div><br />
                <div class="container-login100-form-btn">
                    <div class="wrap-login100-form-btn">
                        <div class="login100-form-bgbtn"></div>
                        <!-- <button class="login100-form-btn" (click)="cancel()"> Cancel </button> -->
                        <button type="submit" class="login100-form-btn"> Submit </button>
                    </div>
                </div>
                <!-- <div class="text-center p-t-23"> <span class="txt1"> Don’t have an account? </span> <a class="txt2" href="#"> Sign Up </a> </div> -->
            </form>
            <div class="container-login100-form-btn">
                <span>Already have an account? <a [routerLink]="['/login']"><u>Login</u></a></span>
            </div>
        </div>
    </div>
</div>