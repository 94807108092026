import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { DataTableDirective } from 'angular-datatables';
import { BaseComponent } from '../base.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-my-courses',
  templateUrl: './my-courses.component.html',
  styleUrls: ['./my-courses.component.css']
})
export class MyCoursesComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  myCourseList: Array<any> = [];
  private readonly onDestroy = new Subscription();
  showModel: boolean = false;
  UserId = sessionStorage.UserId;
  certificateInfo: any;
  starRating = 0;
  review: any;

  constructor(CommonService: CommonService, toastr: ToastrService, private fb: FormBuilder) {
    super(CommonService, toastr);
    this.getCourses();
  }

  ngOnInit(): void {
    this.initialControls();
  }

  initialControls() {
    this.myForm = this.fb.group({
      RATING: ['', Validators.required],
      DESCRIPTION: ['', Validators.required],
      USER_ID: sessionStorage.UserId,
      REVIEW_DATE:[''],
      COURSE_ID: 0,
      COURSESHD_ID: 0,
      REVIEW_ID: 0
    });
  }

  enableOrDisabledSpinner(flag: boolean = true) {
    flag ? this.CommonService.activateSpinner() : this.CommonService.deactivateSpinner();
  }

  getCourses() {
    this.myCourseList = [];
    this.enableOrDisabledSpinner();
    const ob1$ = this.CommonService.getCall('CourseSchedule/GetMyCourses/', `${this.UserId}`).subscribe((res: any) => {
      this.myCourseList = res.dtCourseScehdule;
      this.renderDataTable()
      this.enableOrDisabledSpinner(false);
    }, e => { this.enableOrDisabledSpinner(false); });
    this.onDestroy.add(ob1$);
  }

  ngOnDestroy(): void {
    this.onDestroy.unsubscribe();
    this.dtTrigger.unsubscribe();
  }

  downloadCertificate(courseInfo: any) {
    this.certificateInfo = { ...courseInfo, userId: this.userId, FullName: sessionStorage.FullName };
    setTimeout(() => (<HTMLInputElement>document.getElementById('btnShowModel')).click(), 10);
  }

  closeCertificateEvent(data: any) {
    this.showModel = false;
  }
  validateDownloadCertivicate(REVIEW_ID: number, date: any): boolean {
    if (REVIEW_ID == 0)
      return false;
    return new Date().getTime() > new Date(date).getTime();
  }

  setReviewForm(data: any) {
    this.initialControls();
    if (data.REVIEWS)
      this.myForm.setValue(data.REVIEWS);
    else
      this.myForm.patchValue({ COURSE_ID: data.COURSE_ID, COURSESHD_ID: data.COURSESHD_ID });
    setTimeout(() => { document.getElementById('btnShowReviewModel').click(); }, 100);
  }

  close() {
    setTimeout(() => { this.initialControls(); }, 100);
  }

  onSubmitForm(myForm: any) {
    const payload = JSON.parse(JSON.stringify(this.myForm.getRawValue()));
    console.log(payload);
    // return;
    this.CommonService.postCall("Courses/SaveOrUpdate", payload).subscribe((res: any) => {
      this.review = res;
      this.toastr.success("Review Submitted successfully !!");
      //Close the modal
      document.getElementById('md_close').click();
      this.getCourses();
    }, e => { });
  }
  joinMeeting(url: string) {
      super.getMeetingDetails(url);
  }

}
