import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $: any
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  DEFAULT_PWD = 'ABC123';
  data: any = {};
  loginForm: FormGroup;
  submitted = false;
  fieldTextType: boolean | undefined;

  constructor(private userService: LoginService, private route: Router, private toastr: ToastrService, private CommonService: CommonService, private fb: FormBuilder) { }

  ngOnInit(): void {
    // this.init()
    this.loginForm = this.fb.group({

      userName: ['', Validators.required],
      password: ['', Validators.required],

    });
  }
  get f() { return this.loginForm.controls; }

  registerUser(form: FormGroup) {
    this.submitted = true;
    let user = this.loginForm.getRawValue();
    if (user.userName.trim().length == 0) {
      this.toastr.error("Please enter userName");
      (document.getElementById('userName') as HTMLInputElement).focus();
    }
    if (user.password.trim().length == 0) {
      this.toastr.error("Please enter password");
      (document.getElementById('password') as HTMLInputElement).focus();
    }

    if (this.loginForm.invalid) {
      this.toastr.error("Please enter username or password");
      return;
    }
    // let user = this.data;
    this.userService.login(user).subscribe((succ) => {
      let userData: any = succ ?? {};
      this.CommonService.userId = userData.USERID
      sessionStorage.setItem('UserId', userData.USERID);
      sessionStorage.setItem('Username', userData.FIRSTNAME);
      sessionStorage.setItem('TenantCode', userData.TENANTCODE)
      sessionStorage.setItem('RoleId', userData.ROLEID)
      sessionStorage.setItem('DICTIONARYCODE', userData.DICTIONARYCODE)
      sessionStorage.setItem('SU', userData.samvaad_user);
      sessionStorage.setItem('SP', userData.samvaad_password);
      sessionStorage.setItem('Proctoring', userData.allow_proctoring);
      sessionStorage.setItem('USERTYPE', userData.USERTYPE);
      sessionStorage.setItem('USERTYPE', userData.USERTYPE);
      sessionStorage.MobileNo = userData.MobileNo;
      sessionStorage.USERNAME = userData.USERNAME;
      sessionStorage.PASSWORD = userData.PASSWORD;
      sessionStorage.FullName = `${userData.FIRSTNAME} ${userData.LASTNAME}`.toUpperCase();
      // sessionStorage.setItem('exampad',userData.allow_exampad);
      sessionStorage.profileImage = (userData.USERIMAGE || 'data:,').replace('data:,', "https://i.pinimg.com/736x/89/90/48/899048ab0cc455154006fdb9676964b3.jpg");
      this.CommonService.userImage.next(sessionStorage.profileImage);
      // if (userData.PASSWORD.toUpperCase() == this.DEFAULT_PWD)
      //   this.route.navigate(['home/change-password']);
      if (sessionStorage.courseDetails) {
        const course = JSON.parse(sessionStorage.courseDetails);
        this.route.navigate(['view-course-details'], { state: course });
      }
      else if (userData.USERTYPE == 25)
        this.route.navigate(['home/trainer-dashboard']);
      else
        this.route.navigate(['home/my-courses']);

    }, (err) => {
      console.log(err)
      let e = err.error;

      this.toastr.error(e?.text || 'Please try again later')
    })
  }

  // init() {
  //   let self = this;
  //   $('.input100').each(function () {
  //     $(this).on('blur', function () {
  //       if ($(this).val().trim() != "") {
  //         $(this).addClass('has-val');
  //       }
  //       else {
  //         $(this).removeClass('has-val');
  //       }
  //     })
  //   })

  //   var input = $('.validate-input .input100');

  //   $('.validate-form').on('submit', function () {
  //     var check = true;

  //     for (var i = 0; i < input.length; i++) {
  //       if (validate(input[i]) == false) {
  //         showValidate(input[i]);
  //         check = false;
  //       }
  //     }
  //     if (check) {
  //       self.registerUser()
  //     }
  //     return check;
  //   });


  //   $('.validate-form .input100').each(function () {
  //     $(this).focus(function () {
  //       hideValidate(this);
  //     });
  //   });

  //   function validate(input) {
  //     // return true
  //     if ($(input).attr('type') == 'email' || $(input).attr('name') == 'email') {
  //       return true
  //       if ($(input).val().trim().match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null) {
  //         return false;
  //       }
  //     }
  //     else {
  //       if ($(input).val().trim() == '') {
  //         return false;
  //       }
  //     }
  //   }

  //   function showValidate(input) {
  //     var thisAlert = $(input).parent();

  //     $(thisAlert).addClass('alert-validate');
  //   }

  //   function hideValidate(input) {
  //     var thisAlert = $(input).parent();

  //     $(thisAlert).removeClass('alert-validate');
  //   }

  //   var showPass = 0;
  //   $('.btn-show-pass').on('click', function () {
  //     if (showPass == 0) {
  //       $(this).next('input').attr('type', 'text');
  //       $(this).find('i').removeClass('zmdi-eye');
  //       $(this).find('i').addClass('zmdi-eye-off');
  //       showPass = 1;
  //     }
  //     else {
  //       $(this).next('input').attr('type', 'password');
  //       $(this).find('i').addClass('zmdi-eye');
  //       $(this).find('i').removeClass('zmdi-eye-off');
  //       showPass = 0;
  //     }

  //   });

  // }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType
  }
  onForgotPasswordClick() {
    let user = this.loginForm.getRawValue();
    if (user.userName.trim().length > 0) {
      // if (this.data.userName?.trim().length > 0) {
      // this.route.navigate(['/forgot-password']);
      const payload: string = `Account/ForgotPassword/0/${user.userName.trim()}`;

      this.userService.forgotPassword(payload).subscribe((res: any) => {
        const message: string = "Your Password has been sent to your registered email.";
        if (res.message == message)
          this.toastr.success(message, "Login Page");
        else
          this.toastr.success(res.message, "Login Page");
      }, (err) => {
        console.log(err)
        let e = err.error;
        this.toastr.error(e?.text || 'Please try again later')
      });
    }
    else {
      this.toastr.error("Please enter valid email", "Login Page");
      (document.getElementById("userName") as HTMLInputElement).focus();
    }
  }

}
