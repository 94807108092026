import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
declare var $: any;
declare function googleTranslate(): any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userName: string = sessionStorage.getItem('Username');
  table: Array<any> = [];
  viewMsg: Array<any> = [];
  message: Array<any> = [];
  levelUpdate: any;
  notifications: Array<any> = [];
  OPERATION: any;
  ROLEID = sessionStorage.getItem('RoleId');
  UserRoleName: string;
  userImage: any;
  constructor(private route: Router, private CommonService: CommonService,) {
    this.loadMessage();
    this.UserRoleName = (+sessionStorage.USERTYPE == 25) ? 'Trainer' : 'Trainee';

  }

  ngOnInit(): void {
    this.CommonService.userImage.subscribe((res: any) => {
      this.userImage = res;
    });
    // this.userImage = sessionStorage.profileImage;
    googleTranslate();
  }

  Signout() {
    sessionStorage.clear();
    this.route.navigate(['']);
  }

  changePassword() {
    this.route.navigate(['home/change-password']);
  }

  menuClick() {
    $('#sidebar').toggleClass('slide');
  }
  loadMessage() {
    this.notifications = [];
    if (this.ROLEID == '1') {
      this.OPERATION = 'GETNOTIFICATIONS'
    } else if (this.ROLEID == '2') {
      this.OPERATION = 'TRAINERNOTIFICATIONS'
    } else if (this.ROLEID == '3') {
      this.OPERATION = 'STUDENTNOTIFICATIONS'
    }
    // this.CommonService.getCall(`Notification/GetNotification/${this.OPERATION}/${sessionStorage.getItem('TenantCode')}/${sessionStorage.getItem('UserId')}`).subscribe((res: any) => {
    //  // this.table = [];
    //     this.table = res;
    // }, e => { console.log(e) })
  }

  viewMessage(event: any) {
    this.viewMsg = [];
    this.viewMsg = +event == 0 ? this.table : this.table.filter(e => e.ID == event);
  }

  delete(id: any, type: any, notificationid: any) {
    if (id) {
      type = 2;
    }
    else if (notificationid) {
      type = 1;
    }
    this.CommonService.getCall(`Notification/DeleteNotification/${id}/${type}`).subscribe((res: any) => {

    })
  }

  close() { }

  close1(e: any) {
  }

  getUnViewdNotificationCount() {
    return this.table.filter(m => m.IsRead == false).length;
  }


  apply() {
    document.getElementById('Noti_close')?.click();
  }
}
