<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">My Courses</h5>
                                    </div>
                                    <div class="card-body">

                                        <table id="patreggraph" datatable [dtOptions]="dtOptions"
                                            [dtTrigger]="dtTrigger"
                                            class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                            style="width:100%">
                                            <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                <tr>
                                                    <th> Course Name</th>
                                                    <th> Start Date </th>
                                                    <th> End Date </th>
                                                    <th> Start Time </th>
                                                    <th> End Time </th>
                                                    <th> Tutor Name </th>
                                                    <th> Payment </th>
                                                    <th> Join Link </th>
                                                    <th> Action </th>
                                                   
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of myCourseList">
                                                    <td>{{item.COURSE_NAME}}</td>
                                                    <td>{{item.COURSESHD_STARTDATE | date:'dd-MM-yyyy'}}</td>
                                                    <td>{{item.COURSESHD_ENDDATE | date:'dd-MM-yyyy'}}</td>
                                                    <td>{{item.COURSESHD_STARTTIME | date:'hh:mm a'}}</td>
                                                    <td>{{item.COURSESHD_ENDTIME | date:'hh:mm a'}}</td>
                                                    <td>{{item.TNT_NAME}}</td>
                                                    <td>Paid</td>
                                                    <td>
                                                        <a *ngIf="item.PARTICIPANT_URL" href="javascript: void(0)" (click)="joinMeeting(item.PARTICIPANT_URL)"><span>link</span></a>
                                                    </td>
                                                    <td>
                                                        <!-- [disabled]="isCheck(item.COURSESHD_STARTDATE)" -->
                                                        <!-- <button style="white-space: nowrap;"
                                                            *ngIf="validateDownloadCertivicate(item.COURSESHD_ENDDATE)"
                                                            type="button" class="btn btn-sm btn-success"
                                                            (click)="showModel=true;downloadCertificate(item)"> Download Certificate
                                                        </button> -->
                                                        <div class="dropdown mt-2">
                                                            <button class="btn btn-sm btn-success dropdown-toggle"
                                                                type="button" id="dropdownMenuButton1"
                                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                                Action
                                                            </button>
                                                            <ul class="dropdown-menu"
                                                                aria-labelledby="dropdownMenuButton1">
                                                                <li> <button class="dropdown-item" href="#"
                                                                        *ngIf="validateDownloadCertivicate(item.REVIEW_ID,item.COURSESHD_ENDDATE)"
                                                                        (click)="showModel=true;downloadCertificate(item)">Download
                                                                        Certificate</button></li>
                                                                <li>
                                                                    <input type="button" data-bs-toggle="modal"
                                                                        data-bs-target="#reviewModel"
                                                                        id="btnShowReviewModel" class="d-none">
                                                                    <a class="dropdown-item"
                                                                        (click)="setReviewForm(item)">Review</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<app-certificate *ngIf="showModel" [certificateInfo]="certificateInfo"
    (closeCertificateEvent)="closeCertificateEvent($event)"></app-certificate>
<input type="button" id="btnShowModel" class="d-none" data-bs-toggle="modal" data-bs-target="#modelCertificate"
    data-bs-whatever="@mdo">




<div class="modal fade" id="reviewModel" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg ">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class="modal-title w-100"> Submit Review </h6>

                <button type="button" (click)="close()" class="close " data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (onSubmit)="onSubmitForm(myForm)">
                    <fieldset>
                        <div class="row">
                            <div class="col-xl-4 mb-4">
                                <label class=" form-label line_2 rem_1">
                                    Rating :</label>
                                <ngb-rating [max]="5" [(rate)]="starRating" [readonly]="false" formControlName="RATING">
                                </ngb-rating>

                            </div>
                        </div>

                        <div class="col-xl-12 mb-4">
                            <div class="form">
                                <label class=" form-label line_2 rem_1">
                                    Review Description :</label>
                                <textarea type="text" class="form-control form-control-sm" id="exampleFormControlTextarea1" rows="3"
                                    formControlName="DESCRIPTION" maxlength="100"></textarea>

                            </div>
                        </div>

                    </fieldset>

                    <!-- Modal footer -->
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-sm btn-save" (click)="onSubmitForm(myForm)">Save</button>
                        <button type="button" class="btn btn-sm btn-danger " id='md_close' (click)="close()"
                            data-bs-dismiss="modal">Close</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>