import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { forkJoin, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CommonService } from '../services/common.service';


@Injectable()
export class CoursesResolver implements Resolve<any> {
    courseData: any;
    constructor(private CommonService: CommonService) { }

    resolve() {
        const GetAllCategories = this.CommonService.getCall('GetAllCategories');
        const GetAllCoursesByTrending = this.CommonService.getCall('GetAllCoursesByTrending', '/true');
        const GetAllCoursesByCategoryId = this.CommonService.getCall('GetAllCoursesByCategoryId', `/0`);
        this.courseData = forkJoin([GetAllCategories, GetAllCoursesByTrending, GetAllCoursesByCategoryId]);
        return this.courseData;
    }
}


// If error occurs, this resolve error method won't redirect user to component

// resolve() {
//    return this.usersListService.getUsers().pipe(
//       catchError((error) => {
//          return empty();
//       })
//    )
// }