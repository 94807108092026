<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-0 ps-0">
        <div class="default_class">
            <div class="content-wrapper p-0">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="card rounded shadow-lg">
                                <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                    <span class="float-start">
                                        <h5 class="mb-0">Courses</h5>
                                    </span>
                                    <span class="float-end">
                                        <button type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal"
                                            (click)="submitted = false;add()" data-bs-target="#myModal">Add</button>
                                    </span>
                                </div>
                                <div class="card-body">
                                    <div class="form-group ">
                                        <div class="row">
                                            <div *ngIf='isSuperAdmin' class="col-md-3">
                                                <label for="sel1">Tenant Name : </label>
                                                <select [(ngModel)]='tId ' (change)="changeTname()"
                                                    class="form-control form-control-sm shadow-sm rounded-lg" id="sel1">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                        {{item.TNT_NAME}}</option>
                                                </select>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="pt-4">
                                        <table datatable id="DataTables_Table_0" [dtOptions]="dtOptions"
                                            [dtTrigger]="dtTrigger"
                                            class=" table-striped table-bordered mt-2 pt-2 table-sm small display"
                                            style="width:100%">
                                            <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                <tr>
                                                    <th> Course Name </th>
                                                    <th> Is Certified </th>
                                                    <th> Status </th>
                                                    <th> Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of table">
                                                    <td>{{item.COURSE_NAME}}</td>
                                                    <td>{{item.ISCERTIFIED?'Certified Trainer':'Uncertified Trainer'}}
                                                    </td>

                                                    <td>{{item.STATUS_DESCRIPTION}}</td>
                                                    <td style="display: flex;"> <span data-bs-toggle="modal"
                                                            data-bs-target="#myModal" src="" class="edit_icon"
                                                            (click)="submitted = false;edit(item)">
                                                            <i class="fas fa-pen pt-1 mt-1"></i></span>

                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="text-center" *ngIf="false">
                                        No Records to display
                                    </div>
                                </div>
                                <!-- <div style="padding-left:500px" class="button pt-4"> -->
                                    <!-- <button type="button" class="btn btn-save btn-lg" data-bs-toggle="modal"
                                        data-bs-target="#modelCertificate">Certificate</button> -->
                                    <!-- <button type="button" class="btn btn-save btn-lg" (click)="showModel=true;displayModel()" data-bs-toggle="modal"
                                        data-bs-target="#modelCertificate">Certificate</button>
                                        <app-certificate *ngIf="showModel" (closeCertificateEvent)="closeCertificateEvent($event)"></app-certificate>
                                        <input type="button" id="btnShowModel" class="d-none" data-bs-toggle="modal"
                  data-bs-target="#modelCertificate" data-bs-whatever="@mdo">

                                </div> -->
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-lg ">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header py-2">
                    <h6 *ngIf="!isEdit" class="modal-title w-100"> Add Course </h6>
                    <h6 *ngIf="isEdit" class="modal-title w-100"> Edit Course </h6>
                    <button type="button" (click)="close()" class="close " data-bs-dismiss="modal"><i
                            class="fas fa-times"></i></button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                        <fieldset>
                            <div class="row">
                                <div class="col-xl-4 mb-4">
                                    <div class="form">
                                        <label class="form-label line_2 rem_1"> Course Category <span
                                                class="text-danger">*</span></label>
                                        <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        (change)="onSetCoursePath($event.target.value)"
                                            [ngClass]="{ 'is-invalid': submitted && f.COURSE_CATEGORY_ID.errors }"
                                            formControlName="COURSE_CATEGORY_ID">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let item of courses" [value]="item.COURSE_CATEGORY_ID">
                                                {{item.COURSE_CATEGORY_NAME}}
                                            </option>
                                        </select>

                                        <!-- <div *ngIf="myForm.get('COURSE_CATEGORY_ID').touched">
                                        <span class="text-danger form-text"
                                            *ngIf="myForm.get('COURSE_CATEGORY_ID').hasError('required')">
                                            This field is required.
                                        </span>
                                    </div> -->
                                        <div *ngIf="submitted && f.COURSE_CATEGORY_ID.errors" class="invalid-feedback">
                                            <div *ngIf="f.COURSE_CATEGORY_ID.errors.required">Course Category is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 mb-4">
                                    <div class="form">
                                        <label class=" form-label line_2 rem_1">
                                            Course Name <span class="text-danger">*</span></label>
                                        <input placeholder="" class="form-control form-control-sm"
                                            [ngClass]="{ 'is-invalid': submitted && f.COURSE_NAME.errors }"
                                            formControlName="COURSE_NAME">
                                        <!-- <div *ngIf="myForm.get('COURSE_NAME').touched">
                                        <span class="text-danger form-text"
                                            *ngIf="myForm.get('COURSE_NAME').hasError('required')">
                                            This field is required.
                                        </span>
                                    </div> -->
                                        <div *ngIf="submitted && f.COURSE_NAME.errors" class="invalid-feedback">
                                            <div *ngIf="f.COURSE_NAME.errors.required">Course Name is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-4 mb-4">
                                    <div class="form" *ngIf='isSuperAdmin'>
                                        <span class=" form-label line_2 rem_1">
                                            Is Trending<span class="text-danger">*</span></span>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"
                                                formControlName='COURSE_TRENDING' name="COURSE_TRENDING"
                                                id="TrendingYes" [value]="true">
                                            <label class="form-check-label" for="TrendingYes">Yes</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"
                                                formControlName='COURSE_TRENDING' name="COURSE_TRENDING" id="TrendingNo"
                                                [value]="false">
                                            <label class="form-check-label" for="TrendingNo">No</label>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-xl-12 mb-4">
                                    <div class="form">
                                        <label class=" form-label line_2 rem_1">
                                            Course Description<span class="text-danger">*</span> </label>
                                        <!-- <input placeholder="" class="form-control form-control-sm"
                                        formControlName="COURSE_DESCRIPTION"> -->
                                        <textarea class="form-control form-control-sm" id="exampleFormControlTextarea1"
                                            rows="3"
                                            [ngClass]="{ 'is-invalid': submitted && f.COURSE_DESCRIPTION.errors }"
                                            formControlName="COURSE_DESCRIPTION" minlength="150"> </textarea>
                                        <div *ngIf="submitted && f.COURSE_DESCRIPTION.errors" class="invalid-feedback">
                                            <div *ngIf="f.COURSE_DESCRIPTION.errors.required"> Course Description is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <!--
                            <div class="col-xl-4 mb-4">
                                <div class="form">
                                    <span class=" form-label line_2 rem_1">Course Type</span>
                                    <br>
                                        <div class="form-check form-check-inline">
                                        <input (change)="is_certified=true" class="form-check-input" type="radio" formControlName='ISCERTIFIED'
                                            name="ISCERTIFIED" id="Certified" [value]="true">
                                        <label class="form-check-label" for="Certified">Certified</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input (change)="is_certified=false" class="form-check-input" type="radio" formControlName='ISCERTIFIED'
                                            name="ISCERTIFIED" id="UnCertified" [value]="false">
                                        <label class="form-check-label" for="UnCertified">Uncertified</label>
                                    </div>

                                </div>
                            </div>
                            -->
                                <!-- <div class="col-xl-1 mb-4"></div> -->
                                <!--
                            <div class="col-xl-4 mb-4">
                                <div class="form">
                                    <label *ngIf="is_certified" class=" form-label line_2 rem_1">Upload
                                        Certificates<span class="text-danger">*</span></label>
                                    <label *ngIf="!is_certified" class=" form-label line_2 rem_1">Upload Any
                                        Attachments</label>

                                    <input type="file" #materialFile id="materialFile1" style="min-height: 30px;"
                                    [ngClass]="{ 'is-invalid': submitted && f.UPLOAD_CERTIFIED.errors }"
                                        placeholder="" (change)='changeFile($event)' formControlName="UPLOAD_CERTIFIED"
                                        accept="'pdf', 'png', 'jpg', 'PNG', 'jpeg', 'gif', 'JPEG', 'image'"
                                        class="form-control form-control-sm shadow-sm rounded-lg">

                                    <p>{{fileName}}</p>
                                    <div *ngIf="submitted && f.UPLOAD_CERTIFIED.errors" class="invalid-feedback">
                                        <div *ngIf="f.UPLOAD_CERTIFIED.errors.required"> Upload Certificates is required</div>
                                    </div>
                                </div>
                            </div>
                            -->
                                <!--
                            <div class="col-xl-4 mb-4">
                                <div class="form">
                                    <label class=" form-label line_2 rem_1">
                                        Upload Images <span class="text-danger">*</span></label>

                                     <input type="file" #materialFile1 id="materialFile2" style="min-height: 30px;"
                                        placeholder="" (change)="changeFile1($event)" [ngClass]="{ 'is-invalid': submitted && f.COURSE_IMAGE.errors }"
                                         formControlName="COURSE_IMAGE"
                                        accept="'png', 'jpg', 'PNG', 'jpeg', 'gif', 'JPEG', 'image'"
                                        class="form-control form-control-sm shadow-sm rounded-lg">

                                    <p>{{fileName1}}</p>
                                    <div *ngIf="submitted && f.COURSE_IMAGE.errors" class="invalid-feedback">
                                        <div *ngIf="f.COURSE_IMAGE.errors.required"> Upload Images is required</div>
                                    </div>
                                </div>
                            </div>
                             -->

                                <div class="col-xl-6">
                                    <div class="card h-100">
                                        <div class="card-header">
                                            <h6 class="mb-0">Course Type
                                                <span *ngIf="is_certified" class="text-danger">*</span>
                                            </h6>
                                        </div>
                                        <div class="card-body">
                                            <div class="form mb-3">
                                                <!-- <span class=" form-label line_2 rem_1">Course Type</span> -->
                                                <!-- <br> -->
                                                <div class="form-check form-check-inline">
                                                    <input (change)="is_certified=true" class="form-check-input"
                                                        type="radio" formControlName='ISCERTIFIED' name="ISCERTIFIED"
                                                        id="Certified" [value]="true">
                                                    <label class="form-check-label" for="Certified">Certified</label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input (change)="is_certified=false" class="form-check-input"
                                                        type="radio" formControlName='ISCERTIFIED' name="ISCERTIFIED"
                                                        id="UnCertified" [value]="false">
                                                    <label class="form-check-label"
                                                        for="UnCertified">Uncertified</label>
                                                </div>

                                            </div>
                                            <table class=" table-striped w-100 modal_up_table" style="border: #dee2e6;">
                                                <tr>
                                                    <th>Certificate Name</th>
                                                    <!-- <th>Type</th> -->
                                                </tr>
                                                <tr>
                                                    <td>{{document_name}}</td>
                                                    <!-- <td>image/jpg</td> -->
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="card-footer bg-white">
                                            <!-- formControlName="UPLOAD_CERTIFIED"
                                            [ngClass]="{ 'is-invalid': submitted && f.UPLOAD_CERTIFIED.errors }" -->

                                            <input type="file" id="materialFile1" style="min-height: 30px;"
                                                placeholder="" (change)='changeFile($event)'
                                                accept="'pdf', 'png', 'jpg', 'PNG', 'jpeg', 'gif', 'JPEG', 'image'"
                                                class="form-control form-control-sm shadow-sm rounded-lg d-none">
                                            <button type="button" (click)="onCertificateUpload()"
                                                class="btn btn-primary d-grid gap-2 mx-auto">Upload
                                                {{is_certified?'Certificates':'Attachments if any'}}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6">
                                    <div class="card h-100">
                                        <div class="card-header">
                                            <h6 class="mb-0">Course Image</h6>
                                        </div>
                                        <div class="card-body text-center">
                                            <img [src]="imageURL" class="img-fluid" >
                                        </div>
                                        <div class="card-footer bg-white">
                                            <!-- [ngClass]="{ 'is-invalid': submitted && f.COURSE_IMAGE.errors }"
                                            formControlName="COURSE_IMAGE" 
                                            <input type="file" id="materialFile2" style="min-height: 30px;"  
                                                placeholder="" (change)="changeFile1($event)"
                                                accept="'png', 'jpg', 'PNG', 'jpeg', 'gif', 'JPEG', 'image'"
                                                class="form-control form-control-sm shadow-sm rounded-lg d-none">
                                            <button type="button"
                                                [ngClass]="{ 'is-invalid': submitted && f.COURSE_IMAGE.errors }"
                                                (click)="onCourseImage()"
                                                class="btn btn-primary d-grid gap-2 mx-auto col-7">Upload Image</button>
                                        -->
                                            </div>
                                    </div>
                                </div>



                                <div class="col-xl-4 mb-4">
                                    <div class="form">
                                        <label class=" form-label mt-3">Course Status<span
                                                class="text-danger ">*</span></label>
                                        <select class="form-select form-select-sm shadow-sm rounded-lg" id="sel1"
                                            formControlName="COURSE_STATUS">
                                            <option value=true>Active</option>
                                            <option value=false>InActive </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <div class="modal-footer">
                            <button type="button" *ngIf="!isEdit" (click)="onSubmit(myForm)"
                                class="btn btn-sm btn-save">Save</button>
                            <button type="button" *ngIf="isEdit" (click)="onSubmit(myForm)"
                                class="btn btn-sm btn-save">Update</button>
                            <button type="button" class="btn btn-sm btn-danger " id='md_close' (click)="close()"
                                data-bs-dismiss="modal">Close</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>

    <!-- ---------------certificate popup-------------------->

    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
                <div class="modal-header py-2 text-center">
                    <h6 class="modal-title w-100" id="exampleModalLabel">Certificate</h6>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="certificate-container" id="contentToConvert">
                        <div class="certificate">
                            <div class="water-mark-overlay"></div>
                            <div class="certificate-header">
                            </div>
                            <div class="certificate-body">
                                <div class="certificate-content">
                                    <p class="">
                                        यह प्रमाणित किया जाता है कि सुश्री /श्री/श्रीमती<br>
                                        This is to certify that Ms./Mr./Mrs : <span
                                            class="line">____<b>{{userData.first_name}}</b>____________________________________________________________________________</span>
                                    </p>
                                    <p class="">
                                        ने <br>
                                        has successfully completed
                                        <span class="line">
                                            <b>{{userData.course_name}}</b>______________________________________________________________________________</span>
                                        सफलतापूर्वक उत्तीर्ण
                                        किया
                                    </p>
                                    <p class="">
                                        जिसका सञ्चालन नागर विमानन सुरक्षा ब्यूरो द्वारा <br>
                                        the conducted by Bureau of Civil Aviation Security from
                                        ________<span class="line"><b>{{userData.create_date|
                                                date:'yyyy-MM-dd'}}</b>__________________________________________</span>
                                    </p>
                                    <p class="">
                                        at
                                        ______<span
                                            class="line"><b></b>_________________________________________________________________________________</span>
                                        आयोजिता
                                        किया गया
                                    </p>
                                </div>
                                <div class="certificate-footer">
                                    <div class="row">
                                        <div class="col-md-4 ">
                                            <div class="pl-5 ml-5">
                                                <p>दिनांक<br>
                                                    Date: <span class="line"><b>{{userData.create_date|
                                                            date:'yyyy-MM-dd'}}</b></span>
                                                </p>
                                                <p>
                                                    पंजीकरण संख्या<br>
                                                    Registration No:______________________
                                                </p>
                                            </div>

                                        </div>
                                        <div class="col-md-4" style="padding-left: 90px;padding-top: 25px;">
                                            <qrcode [qrdata]='id' [width]="100" [errorCorrectionLevel]="'M'"></qrcode>
                                        </div>


                                        <div class="col-md-4 ">
                                            <div class="text-center authorised">
                                                <p>
                                                    अधिक्रुत हस्ताक्षरकर्ता<br>
                                                    <b>Authorised Signatory</b><br>

                                                    नई दिल्ली, भारती<br>
                                                    <b> New Delhi, India</b><br>
                                                    नागर विमानन सुरक्षा ब्यूरो द्वारा<br>
                                                    <b> Bureau of Civil Aviation Security headquarters</b>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer ">
                    <button type="button" class="btn btn-danger btn-sm"
                        data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                    <button type="button" class="btn btn-save btn-sm" (click)="convetToPDF()">Download</button>
                </div>
            </div>
        </div>
    </div>

    <!-- ---------------certificate popup-------------------->
<!-- 
    <div class="modal fade" id="modelCertificate" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content" >
                <div class="modal-header py-2 text-center">
                    <h6 class="modal-title w-100" id="exampleModalLabel">Certificate</h6>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body bg_img" id="contentCertificate">
                    <div class="certificate-container" >
                        <app-certificate></app-certificate>
                    </div>
                </div>
                <div class="modal-footer ">
                    <button type="button" class="btn btn-danger btn-sm"
                        data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                    <button type="button" class="btn btn-save btn-sm" (click)="convetToPDF()">Download</button>
                </div>
            </div>
        </div>
    </div> -->