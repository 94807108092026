<section>
    <div class="section page-banner-section" style="background-image: url('../../../../assets/img/inner-banner.png');">
        <div class="shape-2"></div>
        <div class="container">
            <div class="page-banner-wrap">
                <div class="row">
                    <div class="col-lg-12">
                        <!-- Page Banner Content Start -->
                        <div class="page-banner text-center">
                            <h2 class="title">Privacy Policy</h2>
                            <ul class="breadcrumb justify-content-center">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Privacy Policy </li>
                            </ul>
                        </div>
                        <!-- Page Banner Content End -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    </section>
    
    <section class="pt-4 pb-4">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
    
                    <div class="">
                        <div class="">
                            <h5 class="title">Privacy Policy</h5>
                           <p class="text-dark">
                            We appreciate your coming to the Shiksion website. Please be aware that by using this site, you agree to abide by the terms of this privacy statement. You should immediately stop using the Site and cease your access to it if you disagree with these clauses. Your continued use of the site after any modification to these terms has been made signifies your acceptance of those changes. The Company reserves the right to amend these terms from time to time as it deems fit.
                           </p>
                           <p class="text-dark">Your privacy is something that we take very seriously. Any information gathered from specific customers is only used by authorised individuals inside the business who have a legitimate need to know. To provide our clients with the best service possible, we continually review our systems and data.</p>

                           <p class="text-dark fw-bold">
                            Personal Information
                           </p>
                           <p class="text-dark">Your privacy is something that we take very seriously. Any information gathered from specific customers is only used by authorised individuals inside the business who have a legitimate need to know. To provide our clients with the best service possible, we continually review our systems and data.</p>

                           <p class="text-dark fw-bold">
                            Cookies
                           </p>

                           <p class="text-dark ">
                            This Company's website uses cookies, like the majority of interactive websites, to allow us to retrieve user information for each visit. In order to make some sections of our website effective and simple for visitors to use, cookies are utilised in these places.
                           </p>
                           <p class="text-dark fw-bold">
                            Is registration required to use your website?
                           </p>

                           <p class="text-dark">
                            As a policy, you are not required to register to obtain access to portions of the Shiksion website. However, some parts of the website demand registration in order to access particular information.
                           </p>

                           
                           <p class="text-dark fw-bold">
                            No Unauthorized or Prohibited Use
                           </p>

                           <p class="text-dark">
                            Your use of the Shiksion Website is subject to your representation to Samvaad that you will not use the Shiksion Website for any illegal or forbidden purposes as set forth in these terms, conditions, and notices.
                           </p>

                              
                           <p class="text-dark fw-bold">
                            Additional generic terms include
                           </p>

                           <p class="text-dark">
                            The information on these pages is solely for your general knowledge and use. It could change without prior warning.
                           </p>
                           <p class="text-dark">
                            The information on these pages is solely for your general knowledge and use. It could change without prior warning.
                           </p>
                           <p class="text-dark">All trademarks and logos that are not the owner of, or licenced to the operator but are shown on this website are acknowledged on the page.</p>
                        
                           <p class="text-dark">Without previous explicit approval from Samvaad Infotech, you are not permitted to establish a connection to this website from another website or document.</p>
                        </div>
                    </div>
    
    
                </div>
            
            </div>
        </div>
    </section>

